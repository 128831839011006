import React, { useEffect, useState } from "react";
import "../style/global.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Swal from "sweetalert2";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import productImg from "../images/product.png";
import Header from "../particles/Header";
import Footer from "../particles/Footer";
import axios from "axios";

function NextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="block absolute right-3 top-1/2 transform -translate-y-1/2 z-10"
      onClick={onClick}
    >
      <h1 className="text-xl text-[#33bbfb]">
        <FaArrowRight />
      </h1>
    </div>
  );
}

function PrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="block absolute left-3 top-1/2 transform -translate-y-1/2 z-10"
      onClick={onClick}
    >
      <h1 className="text-xl text-[#33bbfb]">
        <FaArrowLeft />
      </h1>
    </div>
  );
}

function ShowProducts() {
  const location = useLocation();
  const navigate = useNavigate();
  const { product } = location.state || {};
  const [sliderIndex, setSliderIndex] = useState(0);
  const [productinfo, setProductinfo] = useState({});
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [cart, setCart] = useState([]);
  const [Coupons, setCoupons] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [totalPrice, setTotalPrice] = useState(product?.Price || 0);
  const apiKey = process.env.REACT_APP_API_KEY;

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    iconColor: "white",
    timer: 3000,
    timerProgressBar: true,
  });

  useEffect(() => {
    const fetchCoupons = async () => {
      try {
        const response = await axios.get("https://api.b4store.net/api/coupons", {
          headers: {
            'Authorization': `Bearer ${apiKey}`,
            'Content-Type': 'application/json'
          }
        });
        const coupons = response.data;
        setCoupons(coupons);
        let isSectionDiscount = false;
        let isProductInSectionDiscount = false;
        let isProductExcludedFromSection = false;
        let discountValue = "N/A";

        if (product?.SectionId !== undefined) {
          const sectionCoupons = coupons.filter(
            (coupon) => coupon.SectionId === product.SectionId
          );
          if (sectionCoupons.length > 0) {
            isSectionDiscount = true;
            const skipFromSection = sectionCoupons.find(
              (coupon) => coupon.SkipFromSectionId === product.ProductId
            );
            if (skipFromSection) {
              isProductExcludedFromSection = true;
            } else {
              const sectionDiscount = sectionCoupons[0].Discount;
              discountValue = sectionDiscount + "%";
              setProductinfo((prevProduct) => ({
                ...prevProduct,
                Discount: sectionDiscount,
              }));
            }
          } else {
            console.log("No discounts available for SectionId.");
          }
        }

        if (product?.ProductId !== undefined && !isSectionDiscount) {
          console.log(
            "Product ID is defined. Checking for product discounts..."
          );

          const productDiscount = coupons.find(
            (coupon) => coupon.ProductId === product.ProductId
          );

          if (productDiscount) {
            discountValue = productDiscount.Discount + "%";
            isProductInSectionDiscount = true;
            console.log(
              `Discount available for product ${product.Name}: ${productDiscount.Discount}%`
            );

            setProductinfo((prevProduct) => ({
              ...prevProduct,
              Discount: productDiscount.Discount,
            }));
          } else {
            console.log(`No discount available for product ${product.Name}.`);
          }
        }
      } catch (error) {
        console.error("Error fetching coupons:", error);
      }
    };

    fetchCoupons();
  }, [product]);


  useEffect(() => {
    setTotalPrice(product?.Price * quantity);
  }, [quantity, product?.Price]);


  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  if (!product) {
    return <div>Loading...</div>;
  }

  const GoToProPage = () => {
    navigate("/products");
  };
  return (
    <div className="font-[Tajawal]">
      <Header setUserInfo={setUserInfo}/>
      {isCartOpen && (
        <div
          id="blur"
          className="z-[99] backdrop-blur-sm fixed w-full h-full top-0 left-0"
        />
      )}
      <div
        id="cart_side"
        className={`transition-all ${
          isCartOpen ? "left-0" : "left-[-420px]"
        } product_bg top-0 fixed z-[100] w-[295px] sm:w-96 h-screen py-6 px-3 sm:px-6 overflow-scroll no-scrollbar select-none flex flex-col`}
      >
        <div className="flex justify-between text-white items-center">
          <button>
            <i className="cursor-pointer fa-solid fa-x text-[#33bbfb]" />
          </button>
          <div className="flex items-center">
            <h1 className="font-bold">سـلة التسـوق</h1>
            <i className="ml-4 text-[#33bbfb] fa-solid fa-basket-shopping" />
          </div>
        </div>
        {/* Ex: CART ITEMS */}
        <div className="my-6 flex justify-between items-center flex-row-reverse text-right">
          <div className="flex w-full flex-row-reverse text-white">
            <img
              src={product.Image || productImg}
              className="w-20 ml-3 h-[4rem] rounded-lg"
              alt="product's image"
              loading="lazy"
              draggable="false"
            />
            <div className="flex flex-col justify-between">
              <p>{product.Name}</p>
              <div className="flex flex-row-reverse">
                <div className="number flex items-center">
                  <span className="minus cursor-pointer">-</span>
                  <input
                    className="num outline-none"
                    type="text"
                    min={1}
                    max={99}
                    defaultValue={1}
                  />
                  <span className="plus cursor-pointer">+</span>
                </div>
                <h1 className="font-[Conthrax] mx-3">${totalPrice}</h1>
              </div>
            </div>
          </div>
          <button className="bg-[#2c384a] w-10 sm:ml-5 h-8 rounded-full text-center text-[#33bbfb]">
            <i className="text-sm fa-solid fa-trash" />
          </button>
        </div>
        {/* CART ITEMS End */}
        <button className="cursor-pointer w-full rounded-full text-lg text-white bg-[#33bbfb] py-1 mt-auto font-bold hover:bg-transparent transition-all border-2 border-solid border-[#33bbfb]">
          إتمام الشراء
        </button>{" "}
      </div>
      {/* CART SIDE MENU End */}
      {/* Header Section End */}
      <section className="w-full p-5 md:p-10 max-w-screen-2xl mx-auto">
        <div className="justify-between bg-[#0a0d11] w-[90%] mx-auto p-5 rounded-3xl flex lg:flex-row flex-col">
          <div className="w-full lg:w-[60%]">
            <h1 className="text-white text-4xl">{product.Name}</h1>
            <h2 className="text-white mt-10">{product.Description}</h2>
            <div className="flex flex-col justify-center items-center bg-[#0f131a] w-fit px-5 py-4 rounded-2xl my-4">
              {/* <div className="number flex items-center">
                <span
                  className="minus cursor-pointer select-none"
                  onClick={() => handleQuantityChange(-1)}
                >
                  -
                </span>
                <input
                  className="num outline-none text-white w-8 h-8"
                  type="text"
                  value={quantity}
                  readOnly
                />
                <span
                  className="plus cursor-pointer select-none"
                  onClick={() => handleQuantityChange(1)}
                >
                  +
                </span>
              </div> */}
              <h1 className="font-[Conthrax] mx-3 text-white">
                {totalPrice}$
              </h1>
              <button
                onClick={GoToProPage}
                className="my-3 bg-[#33bbfb] rounded px-3 text-white w-44"
              >
               تصفح باقي المنتجات
              </button>
            </div>
            {productinfo.Discount && (
              <h1
                className="w-full text-left px-3 py-2 rounded text-white"
                style={{
                  background: "linear-gradient(90deg,#33bbfb, transparent)",
                  direction: "rtl",
                }}
              >
                احصل على كود تخفيض{" "}
                <span className="font-[Conthrax]">{productinfo.Discount}%</span>
              </h1>
            )}
          </div>
          {product.VideoLink ? (
            <div className="owl-carousel owl-theme mx-auto lg:mx-0 ">
              <Slider {...sliderSettings} initialSlide={sliderIndex}>
                <div className="item">
                  <img
                    className="rounded-2xl w-full h-[300px] object-cover mt-10"
                    src={product.Image || productImg}
                    alt={product.Name}
                  />
                </div>
                <div className="item">
                  <iframe
                    style={{ zIndex: 1 }}
                    className="rounded-2xl w-full h-[300px] object-cover"
                    src={product.VideoLink}
                    title="Product video"
                  ></iframe>
                </div>
              </Slider>
            </div>
          ) : (
            <div className="item mx-auto lg:mx-0">
              <img
                className="rounded-2xl w-full h-[300px] object-cover mt-10"
                src={product.Image || productImg}
                alt={product.Name}
              />
            </div>
          )}
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default ShowProducts;
