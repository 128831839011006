import React, { useEffect } from "react";

const PayPalButton = ({ amount, onSuccess }) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://www.paypal.com/sdk/js?client-id=AUg2PA7ynrx7yCAZm6xse1X2sNieGvyYgC4P4NgYIaM9-O1aJ01v_p41ESZ_KAVSOPZZ7DoaFjpzfwIL`;
    script.addEventListener("load", () => {
      window.paypal.Buttons({
        createOrder: (data, actions) => {
          return actions.order.create({
            purchase_units: [{
              amount: {
                value: amount
              }
            }]
          });
        },
        onApprove: (data, actions) => {
          return actions.order.capture().then(details => {
            onSuccess(details);
          });
        }
      }).render("#paypal-button-container");
    });
    document.body.appendChild(script);
  }, [amount, onSuccess]);

  return (
    <div>
      <div style={{marginTop: "2vh"}} id="paypal-button-container"></div>
    </div>
  );
};

export default PayPalButton;
