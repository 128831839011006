import React from "react";
import "../style/global.css";

function Footer() {
  return (
    <footer className="w-full px-10 my-10 flex flex-col">
      <div className="flex gap-3">
        <a
          href="https://discord.gg/b4s"
          className="w-8 h-8 rounded text-white bg-[#33bbfb] flex items-center justify-center hover:bg-[#1C78A1] transition-all"
        >
          <i className="fa-brands fa-discord"></i>
        </a>
        <a
          href="https://youtube.com/@b4store_?feature=shared"
          className="w-8 h-8 rounded text-white bg-[#33bbfb] flex items-center justify-center hover:bg-[#1C78A1] transition-all"
        >
          <i class="fa-brands fa-youtube"></i>
        </a>
        <a
          href="https://wa.me/message/TLIG6JFKPLZED1"
          className="w-8 h-8 rounded text-white bg-[#33bbfb] flex items-center justify-center hover:bg-[#1C78A1] transition-all"
        >
          <i className="fa-brands fa-whatsapp"></i>
        </a>
        <a
          href="https://www.instagram.com/b4_st0re?igsh=MjFzY3pjdHRreTYx"
          className="w-8 h-8 rounded text-white bg-[#33bbfb] flex items-center justify-center hover:bg-[#1C78A1] transition-all"
        >
          <i className="fa-brands fa-instagram"></i>
        </a>
      </div>
      <div className="border-t-2 border-solid border-[#333333] w-full my-3">
        <div className="w-full flex justify-between mt-3">
          <p className="text-[#dadada]">
            الأسعار مدرجة بدون ضريبة القيمة المضافة
          </p>
          <p className="text-[#dadada]">
            جميع الحقوق محفوظة لدي{" "}
            <span className=" text-[#33bbfb]">بي فور ستور</span> 2024{" "}
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
