import React from 'react';
import ReactDOM from 'react-dom/client';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';
import Data from './Data';
import reportWebVitals from './reportWebVitals';

if (process.env.NODE_ENV === 'production') {
    disableReactDevTools();
  }

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Data />
);


reportWebVitals();
