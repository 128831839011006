import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "../style/global.css";
import Swal from "sweetalert2";
import $ from "jquery";
import "jquery-ui/ui/widgets/slider";
import Layer from "../images/Layer 3.png";
import Header from "../particles/Header";
import Footer from "../particles/Footer";
import axios from "axios";

function Products() {
  const [products, setProducts] = useState([]);
  const [sections, setSections] = useState([]);
  const [selectedSection, setSelectedSection] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [cart, setCart] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const apiKey = process.env.REACT_APP_API_KEY;

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    iconColor: "white",
    timer: 3000,
    timerProgressBar: true,
  });


  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get("https://api.b4store.net/api/products", {
          headers: {
            'Authorization': `Bearer ${apiKey}`,
            'Content-Type': 'application/json'
          }
        });
        setProducts(response.data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, [apiKey]);


  useEffect(() => {
    const fetchSections = async () => {
      try {
        const response = await axios.get("https://api.b4store.net/api/sections", {
          headers: {
            'Authorization': `Bearer ${apiKey}`,
            'Content-Type': 'application/json'
          }
        });
        setSections(response.data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchSections();
  }, [apiKey]);

  useEffect(() => {
    $("#slider-range").slider({
      range: true,
      min: 0,
      max: 1000,
      step: 1,
      values: [0, 1000],
      slide: function (e, ui) {
        var min = Math.floor(ui.values[0]);
        $(".slider-time").html(min + "$");

        var max = Math.floor(ui.values[1]);
        $(".slider-time2").html(max + "$");

        $(".produc_tsBox").each(function () {
          var price = parseInt($(this).find(".product_price").text());
          if (price >= min && price <= max) {
            $(this).show();
          } else {
            $(this).hide();
          }
        });
      },
    });

    $("#search-input")?.on("input", function () {
      let searchTerm = $(this).val().trim().toLowerCase();
      $(".produc_tsBox").each(function () {
        let productName = $(this).find(".product_name").text().toLowerCase();
        if (productName.includes(searchTerm)) {
          $(this).show();
        } else {
          $(this).hide();
        }
      });
    });
  }, []);

  const handlePreview = (product) => {
    navigate("/ShowProducts", { state: { product } });
  };

  const toggleCart = () => {
    setIsCartOpen(!isCartOpen);
  };

  const addToCart = (product) => {
    if (!userInfo) {
      return Toast.fire({
        icon: "info",
        title: `الرجاء تسجيل دخولك اولا`,
        color: "white",
        background: "#33bbfb",
      });
    }
    setCart((prevCart) => {
      const existingProduct = prevCart.find(
        (item) => item.ProductId === product.ProductId
      );
      if (existingProduct) {
        return prevCart.map((item) =>
          item.ProductId === product.ProductId
            ? { ...item, Quantity: item.Quantity + 1 }
            : item
        );
      }
      return [...prevCart, { ...product, Quantity: 1 }];
    });
    setIsCartOpen(true);
  };

  const updateQuantity = (productId, delta) => {
    setCart((prevCart) =>
      prevCart.map((item) =>
        item.ProductId === productId
          ? { ...item, Quantity: Math.max(1, item.Quantity + delta) }
          : item
      )
    );
  };

  const removeFromCart = (productId) => {
    setCart((prevCart) =>
      prevCart.filter((item) => item.ProductId !== productId)
    );
  };

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const sectionId = query.get("Id");
    if (sectionId) {
      setSelectedSection(sectionId);
    }
  }, [location.search]);

  const handleSectionClick = (sectionName, sectionId) => {
    setSelectedSection(sectionId);
    navigate(`/Products?section=${sectionName}&Id=${sectionId}`);
  };

  const filteredProducts = selectedSection
    ? products.filter((product) => product.SectionId === selectedSection)
    : products;

  const handleCheckout = () => {
      if (cart.length === 0) {
        Toast.fire({
          icon: "info",
          title: `عربتك فارغة، لا يمكن الوصول إلى إتمام الشراء في الوقت الحالي`,
          color: "white",
          background: "#33bbfb",
        });
      } else {
        navigate("/Check", { state: { cart } });
      }
  };

  return (
    <div className="font-[Tajawal]">
      <Header setUserInfo={setUserInfo} toggleCart={toggleCart} />

      {isCartOpen && (
          <div
            id="blur"
            onClick={toggleCart}
            className="z-[99] backdrop-blur-sm fixed w-full h-full top-0 left-0"
          />
        )}
        <div
          id="cart_side"
          className={`transition-all ${
            isCartOpen ? "left-0" : "left-[-420px]"
          } product_bg top-0 fixed z-[100] w-[295px] sm:w-96 h-screen py-6 px-3 sm:px-6 overflow-scroll no-scrollbar select-none flex flex-col`}
        >
          <div className="flex justify-between text-white items-center">
            <button onClick={toggleCart}>
              <i className="cursor-pointer fa-solid fa-x text-[#33bbfb]" />
            </button>
            <div className="flex items-center">
              <h1 className="font-bold">سـلة التسـوق</h1>
              <i className="ml-4 text-[#33bbfb] fa-solid fa-basket-shopping" />
            </div>
          </div>
        {/* Ex: CART ITEMS */}

        {cart.map((item, index) => (
            <div
              key={index}
              className="my-6 flex justify-between items-center flex-row-reverse text-right"
            >
              <div className="flex w-full flex-row-reverse text-white">
                <img
                  src={item.Image}
                  className="w-20 ml-3 h-[4rem] rounded-lg"
                  alt="product's image"
                  loading="lazy"
                  draggable="false"
                />
                <div className="flex flex-col justify-between">
                  <p>{item.Name}</p>
                  <div className="flex flex-row-reverse items-center">
                    <div className="number flex items-center">
                      <span
                        className="minus cursor-pointer"
                        onClick={() => updateQuantity(item.ProductId, -1)}
                      >
                        -
                      </span>
                      <input
                        className="num outline-none mx-2"
                        type="text"
                        min={1}
                        max={99}
                        value={item.Quantity}
                        readOnly
                      />
                      <span
                        className="plus cursor-pointer"
                        onClick={() => updateQuantity(item.ProductId, 1)}
                      >
                        +
                      </span>
                    </div>
                    <h1 className="font-[Conthrax] mx-3">
                      {item.Price * item.Quantity} $
                    </h1>
                  </div>
                </div>
              </div>
              <button
                onClick={() => removeFromCart(item.ProductId)}
                className="bg-[#2c384a] w-10 sm:ml-5 h-8 rounded-full text-center text-[#33bbfb]"
              >
                <i className="text-sm fa-solid fa-trash" />
              </button>
            </div>
          ))}
        {/* CART ITEMS End */}
        <button 
          onClick={handleCheckout}
          className="cursor-pointer w-full rounded-full text-lg text-white bg-[#33bbfb] py-1 mt-auto font-bold hover:bg-transparent transition-all border-2 border-solid border-[#33bbfb]">
          إتمام الشراء
        </button>
      </div>
      {/* CART SIDE MENU End */}
      {/* Header Section End */}
      <section className="w-full p-5 md:p-10 max-w-screen-2xl mx-auto">
        <div className="flex w-full justify-center lg:flex-row flex-col-reverse">
          <div className="flex flex-col lg:pr-4 w-full">
            <div className="w-full text-center">
              <div className="flex items-center justify-end w-full justify-center">
                <div className="mx-auto flex justify-end items-center">
                  <input
                    id="search-input"
                    className="mb-4 w-[270px] md:w-[550px] text-center px-10 bg-[#11131a] rounded text-gray-400 outline-none py-1"
                    placeholder="ابحث عن منتجك"
                  />
                  <i className="mr-3 mb-4 text-[#33bbfb] absolute fa-solid fa-magnifying-glass" />
                </div>
              </div>
            </div>
            <div
              className="flex flex-wrap gap-9 justify-center w-full max-h-[30rem] overflow-y-scroll"
              style={{ direction: "rtl" }}
            >
              {filteredProducts.map((product, index) => (
                <div
                  key={index}
                  className="produc_tsBox product_bg rounded-xl p-3 flex flex-col w-60 h-fit transition-all"
                >
                  <div className="flex w-full justify-between text-white text-sm flex-row-reverse items-center">
                    {product.StockQuantity === 0 || product.Expired === 1 ? (
                      <i className="fa-solid fa-xmark text-[#33bbfb] text-xl pl-1"></i>
                    ) : product.StockQuantity === 0 ? (
                      <i className="fa-solid fa-xmark text-[#33bbfb] text-xl pl-1"></i>
                    ) : (
                      <button onClick={() => addToCart(product)} className="transition-all hover:bg-[#33bbfb] hover:text-white border-2 border-[#33bbfb] px-2 py-1 rounded-2xl text-xs text-[#33bbfb]">
                        اضف +
                      </button>
                    )}
                    <p className="product_name">{product.Name}</p>
                  </div>
                  <img
                    src={product.Image}
                    alt={product.Name}
                    loading="lazy"
                    draggable="false"
                    className="w-full select-none my-3 rounded-lg"
                  />
                  <div className="flex w-full justify-between text-white text-sm flex-row-reverse items-center">
                    <button onClick={() => handlePreview(product)} className="border-2 bg-[#33bbfb] border-[#33bbfb] px-5 font-bold py-1 rounded-2xl text-xs text-white transition-all hover:bg-transparent">
                      معاينة
                    </button>
                    <h1 className="font-[Conthrax] text-xl product_price">
                      {product.StockQuantity === 0 || product.Expired === 1
                        ? "نفذت الكمية"
                        : product.Price + "$"}
                    </h1>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="flex flex-col product_bg my-10 h-[35rem] rounded-3xl w-full lg:w-[33rem] p-5">
            <div className="w-full text-right flex flex-col">
              <h1 className="text-white text-lg mb-2">الأقسام</h1>
              {sections.map((section, index) => (
                <a
                  key={index}
                  className="text-[#33bbfb] hover:cursor-pointer text-sm"
                  onClick={() =>
                    handleSectionClick(section.SectionName, section.SectionId)
                  }
                >
                  {section.SectionName}{" "}
                  <i className="fa-solid fa-angle-right" />
                </a>
              ))}
            </div>
            <div id="time-range">
              <div className="w-full h-0.5  my-4 bg-[#333333]" />
              <h1 className="title_price text-right text-bold text-white mb-3">
                نظاق السعر
              </h1>
              <div className="sliders_step1">
                <div
                  className="flat-slider ui-slider ui-slider-horizontal ui-widget ui-widget-content ui-corner-all"
                  id="slider-range"
                >
                  <span
                    className="ui-slider-handle ui-state-default ui-corner-all"
                    tabIndex={0}
                    style={{ left: "0%" }}
                  />
                  <span
                    className="ui-slider-handle ui-state-default ui-corner-all"
                    tabIndex={0}
                    style={{ left: "100%" }}
                  />
                </div>
              </div>
              <div className="slider_time_container my-4">
                <span className="slider-time">0$</span>
                <span className="slider-time2">1000$</span>
              </div>
            </div>
            <img
              className="w-96 mx-auto mb-4 mt-auto select-none"
              src={Layer}
              alt="logo"
              draggable="false"
              loading="lazy"
            />
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Products;
