import React, { useState, useEffect } from "react";
import axios from "axios";
import Logo from "../images/logo_new.png";

function Header({ setUserInfo, toggleCart }) {
  const [user, setUserDiscordInfo] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const apiKey = process.env.REACT_APP_API_KEY;
  
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get("https://api.b4store.net/api/user", {
          headers: {
            'Authorization': `Bearer ${apiKey}`,
            'Content-Type': 'application/json'
          },
          withCredentials: true
        });
        setUserDiscordInfo(response.data);
        setUserInfo(response.data);
      } catch (error) {
        console.error("Error fetching user info:", error);
      }
    };

    fetchUser();
  }, [apiKey]);

  const handleLoginClick = () => {
    window.location.assign("https://api.b4store.net/auth/redirect");
  };

  const handleLogOutClick = () => {
    window.location.assign("https://api.b4store.net/logout");
  };

  const toggleMenu = () => {
    const mo = document.getElementById("mobile");
    if (!isMenuOpen) {
      mo.style.height = "210px";
      setIsMenuOpen(true);
    } else {
      mo.style.height = "";
      setIsMenuOpen(false);
    }
  };

  return (
<div className="font-[Tajawal]">
<header className="py-4 px-5 w-full flex items-center justify-between max-w-screen-2xl mx-auto">
        <a href="/">
          <img
            src={Logo}
            className="w-20"
            draggable="false"
            loading="lazy"
            alt="Logo"
          />
        </a>
        <div className="mx-auto text-white hidden gap-5 flex-row-reverse md:flex">
          <button
            onClick={user ? handleLogOutClick : handleLoginClick}
            className="select-none rounded bg-[#33bbfb] py-2 px-2 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-blue-500/20 transition-all hover:opacity-[0.85] hover:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
            type="button"
          >
            {user ? "تسجيل خروج" : "تسجيل دخول"}
          </button>
          <button
            onClick={toggleCart}
            className="text-[#33bbfb] hover:text-white transition-all"
          >
            <i className="fa-solid fa-basket-shopping" />
          </button>
          <a
            href="/"
            className="text-white hover:text-[#33bbfb] transition-all"
          >
            الرئيسية
          </a>
          <a
            href="/products"
            className="text-white hover:text-[#33bbfb] transition-all"
          >
            المنتجات
          </a>
          <a
            href="/"
            className="text-white hover:text-[#33bbfb] transition-all"
          >
            الشروط والأحكام
          </a>
          <a
            href="/"
            className="text-white hover:text-[#33bbfb] transition-all"
          >
            سياسة الخصوصية
          </a>
        </div>
        <button
          onClick={toggleMenu}
          className="flex md:hidden text-white text-4xl"
        >
          ≡
        </button>
      </header>
      <div
        id="mobile"
        className="flex-col-reverse text-center gap-2 w-full flex md:!h-0 transition-all h-0 overflow-hidden mb-4 items-center justify-center"
      >
        <button
          onClick={toggleCart}
          className="text-[#33bbfb] hover:text-white transition-all"
        >
          <i className="fa-solid fa-basket-shopping" />
        </button>
        <button
          onClick={user ? handleLogOutClick : handleLoginClick}
          className="w-20 select-none rounded bg-[#33bbfb] py-2 px-2 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-blue-500/20 transition-all hover:opacity-[0.85] hover:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
          type="button"
        >
          {user ? "تسجيل خروج" : "تسجيل دخول"}
        </button>
        <a href="/" className="text-white hover:text-[#33bbfb] transition-all">
          الرئيسية
        </a>
        <a href="/products" className="text-white hover:text-[#33bbfb] transition-all">
          المنتجات
        </a>
        <a href="/" className="text-white hover:text-[#33bbfb] transition-all">
          الشروط والأحكام
        </a>
        <a href="/" className="text-white hover:text-[#33bbfb] transition-all">
          سياسة الخصوصية
        </a>
      </div>
</div>
  );
}

export default Header;
