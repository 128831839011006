import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../particles/Header";
import "../style/global.css";
import Shape from "../images/Shape 495.png";
import Hue_Saturation from "../images/Hue_Saturation 2.png";
import axios from "axios";
import Swal from "sweetalert2";
import Footer from "../particles/Footer";
const config = {
  Terms: {
    [1] : "الموافقة في استخدام موقعنا، فإنك توافق بموجبه على سياسة الخصوصية الخاصة بنا وتوافق على شروطها",
    [2] : "وأسباب مطالبتك بتقديمها، لك عندما نطلب منك تقديم معلوماتك الشخصية , إذا اتصلت بنا مباشرة، فقد نتلقى معلومات إضافية عنك مثل اسمك وعنوان بريدك الإلكتروني ورقم هاتفك ومحتويات الرسالة و/أو المرفقات التي قد ترسلها إلينا، وأي معلومات أخرى قد تختار تقديمها , عندما تقوم بالتسجيل للحصول على حساب، قد نطلب معلومات الاتصال الخاصة بك، بما في ذلك عناصر مثل الاسم واسم الشركة والعنوان وعنوان البريد الإلكتروني ورقم الهاتف",
    [3] : "كيف نستخدم معلوماتك , نحن نستخدم المعلومات التي نجمعها بطرق مختلفة، بما في ذلك, فهم وتحليل كيفية استخدامك لموقعنا ,تطوير منتجات وخدمات وميزات ووظائف جديدة, التواصل معك، إما بشكل مباشر أو من خلال أحد شركائنا، بما في ذلك خدمة العملاء، لتزويدك بالتحديثات والمعلومات الأخرى المتعلقة بالموقع، ولأغراض التسويق والترويج, نرسل لك رسائل عبر البريد الإلكتروني",
    [4] : "ملفات السجل تتبع بي فور الإجراء القياسي لاستخدام ملفات السجل , تقوم هذه الملفات بتسجيل الزوار عند زيارتهم لمواقع الويب , جميع الشركات تفعل ذلك وهو جزء من تحليلات خدمات الشركة , تتضمن المعلومات التي تم جمعها بواسطة ملفات السجل عناوين بروتوكول الإنترنت (IP) ونوع المتصفح، وموفر خدمة الإنترنت (ISP)، وختم التاريخ والوقت، وصفحات الإحالة/الخروج، وربما عدد النقرات ولا ترتبط هذه بأي معلومات تحدد هويتك الشخصية",
    [5] : "الغرض من المعلومات هو تحليل الاتجاهات وإدارة الموقع وتتبع حركة المستخدمين على الموقع وجمع المعلومات الديموغرافية",
    [6] : "ملفات تعريف الارتباط و إشارات الويب , مثل أي موقع ويب آخر، تستخدم بي فور ملفات تعريف الارتباط , تستخدم ملفات تعريف الارتباط هذه لتخزين المعلومات بما في ذلك تفضيلات الزوار والصفحات الموجودة على موقع الويب التي وصل إليها الزائر أو زارها , يتم استخدام هذه المعلومات لتحسين تجربة المستخدمين من خلال تخصيص محتوى صفحة الويب الخاصة بنا", 
    [7] :"التغييرات في سياسة الخصوصية , قد نقوم بتحديث سياسة الخصوصية الخاصة بنا من وقت لآخر, لذا ننصحك بمراجعة هذه الصفحة بشكل دوري لمعرفة أي تغييرات ,تسري التغييرات فورا بعد نشرها على هذه الصفحة"
},
Rules: {
    [1] :"يجب عليك الاحترام و الالتزام بالأداب العامة، وتجنب السبام والعنصرية",
    [2] :"جميع المنتجات التي يتم عرضها خاصة بمتجرنا",
    [3] :"يمنع نشر اي عمل او منتج يخص متجرنا , والعميل هو المسؤول الاول عن المشاكل التي تخص هذا الشأن",
    [4] :"نخلي مسؤوليتنا تماما من اي سوء استخدام يتم من قبل المستخدم على السكربت سواء من حذف او تعديل",
    [5] :"يمنع منعا باتا النشر بجميع انواعه او تبادل السكربتات او نشر سكربتات مسروقة",
    [6] :"جميع المنتجات والخدمات غير قابلة للاسترجاع والاستبدال",
},
}

function Home() {
  const [isOpen, setIsOpen] = useState(false);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [cart, setCart] = useState([]);
  const [userInfo, setUserInfo] = useState(null);
  const navigate = useNavigate();
  const apiKey = process.env.REACT_APP_API_KEY;


  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    iconColor: "white",
    timer: 3000,
    timerProgressBar: true,
  });

  const handleToggleShow = () => {
    const blockSection = document.getElementById("showBlock");
    const productContainer = document.getElementById("productContainer");
    const sectionContainer = document.getElementById("sectionContainer");

    if (!blockSection || !productContainer || !sectionContainer) {
      console.error("One or more elements not found");
      return;
    }

    if (!isOpen) {
      blockSection.style.position = "relative";
      blockSection.style.height = "auto";
      blockSection.style.backgroundColor = "transparent";
      blockSection.style.padding = "0";
      blockSection.style.margin = "0";
      productContainer.style.height = "100%";
      sectionContainer.style.height = "auto";
    } else {
      blockSection.style.position = "";
      blockSection.style.height = "";
      blockSection.style.backgroundColor = "";
      blockSection.style.padding = "";
      blockSection.style.margin = "";
      productContainer.style.height = "";
      sectionContainer.style.height = "";
    }

    setIsOpen(!isOpen);
  };

  const toggleCart = () => {
    setIsCartOpen(!isCartOpen);
  };

  const addToCart = (product) => {
    if (!userInfo) {
      return Toast.fire({
        icon: "info",
        title: `الرجاء تسجيل دخولك اولا`,
        color: "white",
        background: "#33bbfb",
      });
    }
    setCart((prevCart) => {
      const existingProduct = prevCart.find(
        (item) => item.ProductId === product.ProductId
      );
      if (existingProduct) {
        return prevCart.map((item) =>
          item.ProductId === product.ProductId
            ? { ...item, Quantity: item.Quantity + 1 }
            : item
        );
      }
      return [...prevCart, { ...product, Quantity: 1 }];
    });
    setIsCartOpen(true);
  };

  const updateQuantity = (productId, delta) => {
    setCart((prevCart) =>
      prevCart.map((item) =>
        item.ProductId === productId
          ? { ...item, Quantity: Math.max(1, item.Quantity + delta) }
          : item
      )
    );
  };

  const removeFromCart = (productId) => {
    setCart((prevCart) =>
      prevCart.filter((item) => item.ProductId !== productId)
    );
  };

  const handleCheckout = () => {
    if (cart.length === 0) {
      Toast.fire({
        icon: "info",
        title: `عربتك فارغة، لا يمكن الوصول إلى إتمام الشراء في الوقت الحالي`,
        color: "white",
        background: "#33bbfb",
      });
    } else {
      navigate("/Check", { state: { cart } });
    }
  };

  const handlePreview = (product) => {
    navigate("/ShowProducts", { state: { product } });
  };


  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get("https://api.b4store.net/api/products", {
          headers: {
            'Authorization': `Bearer ${apiKey}`,
            'Content-Type': 'application/json'
          }
        });
        setProducts(response.data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, [apiKey]);
  

  return (
    <div className="font-[Tajawal]">
      <Header setUserInfo={setUserInfo} toggleCart={toggleCart} />
      <>
        {isCartOpen && (
          <div
            id="blur"
            onClick={toggleCart}
            className="z-[99] backdrop-blur-sm fixed w-full h-full top-0 left-0"
          />
        )}
        <div
          id="cart_side"
          className={`transition-all ${
            isCartOpen ? "left-0" : "left-[-420px]"
          } product_bg top-0 fixed z-[100] w-[295px] sm:w-96 h-screen py-6 px-3 sm:px-6 overflow-scroll no-scrollbar select-none flex flex-col`}
        >
          <div className="flex justify-between text-white items-center">
            <button onClick={toggleCart}>
              <i className="cursor-pointer fa-solid fa-x text-[#33bbfb]" />
            </button>
            <div className="flex items-center">
              <h1 className="font-bold">سـلة التسـوق</h1>
              <i className="ml-4 text-[#33bbfb] fa-solid fa-basket-shopping" />
            </div>
          </div>
          {/* Cart Items */}
          {cart.map((item, index) => (
            <div
              key={index}
              className="my-6 flex justify-between items-center flex-row-reverse text-right"
            >
              <div className="flex w-full flex-row-reverse text-white">
                <img
                  src={item.Image}
                  className="w-20 ml-3 h-[4rem] rounded-lg"
                  alt="product's image"
                  loading="lazy"
                  draggable="false"
                />
                <div className="flex flex-col justify-between">
                  <p>{item.Name}</p>
                  <div className="flex flex-row-reverse items-center">
                    <div className="number flex items-center">
                      <span
                        className="minus cursor-pointer"
                        onClick={() => updateQuantity(item.ProductId, -1)}
                      >
                        -
                      </span>
                      <input
                        className="num outline-none mx-2"
                        type="text"
                        min={1}
                        max={99}
                        value={item.Quantity}
                        readOnly
                      />
                      <span
                        className="plus cursor-pointer"
                        onClick={() => updateQuantity(item.ProductId, 1)}
                      >
                        +
                      </span>
                    </div>
                    <h1 className="font-[Conthrax] mx-3">
                      {item.Price * item.Quantity} $
                    </h1>
                  </div>
                </div>
              </div>
              <button
                onClick={() => removeFromCart(item.ProductId)}
                className="bg-[#2c384a] w-10 sm:ml-5 h-8 rounded-full text-center text-[#33bbfb]"
              >
                <i className="text-sm fa-solid fa-trash" />
              </button>
            </div>
          ))}
          <button
            onClick={handleCheckout}
            className="cursor-pointer w-full rounded-full text-lg text-white bg-[#33bbfb] py-1 mt-auto font-bold hover:bg-transparent transition-all border-2 border-solid border-[#33bbfb]"
          >
            إتمام الشراء
          </button>
        </div>

        <section className="w-full p-5 md:p-10 max-w-screen-2xl mx-auto">
          <h1 className="text-white text-right text-2xl md:text-4xl">
            اختر <span className="text-[#33bbfb]">منتجك المناسب</span> وقم
            بإضافته إلى السلة
          </h1>
          <div
            id="sectionContainer"
            className={`w-full bg-[#0c0f14] py-10 rounded-3xl my-10 flex flex-col items-center ${
              isOpen ? "h-auto" : "h-[30rem]"
            } overflow-hidden relative`}
          >
            <div
              id="productContainer"
              className={`transition-all product_container flex justify-center flex-wrap gap-5 ${
                isOpen ? "h-full" : "h-[30rem]"
              } overflow-hidden px-8 py-5 z-10`}
            >
              {products.map((product, index) => (
                <div
                  key={index}
                  className="product_bg rounded-xl p-3 flex flex-col w-60 h-fit transition-all"
                >
                  <div className="flex w-full justify-between text-white text-sm">
                    {product.StockQuantity === 0 || product.Expired === 1 ? (
                      <i className="fa-solid fa-xmark text-[#33bbfb] text-xl pl-1"></i>
                    ) : product.StockQuantity === 0 ? (
                      <i className="fa-solid fa-xmark text-[#33bbfb] text-xl pl-1"></i>
                    ) : (
                      <button
                        onClick={() => addToCart(product)}
                        className="transition-all hover:bg-[#33bbfb] hover:text-white border-2 border-[#33bbfb] px-2 py-1 rounded-md text-xs text-[#33bbfb]"
                      >
                        اضف +
                      </button>
                    )}

                    <p>{product.Name}</p>
                  </div>
                  <img
                    src={product.Image}
                    alt={product.Name}
                    loading="lazy"
                    draggable="false"
                    className="w-full select-none my-3 rounded-lg"
                  />
                  <div className="flex w-full justify-between text-white text-sm">
                    <button
                      onClick={() => handlePreview(product)}
                      className="border-2 bg-[#33bbfb] border-[#33bbfb] px-5 font-bold py-1 rounded-md text-xs text-white transition-all hover:bg-transparent"
                    >
                      معاينة
                    </button>

                    <h1 className="font-[Conthrax] product_price text-xl">
                      {product.StockQuantity === 0 || product.Expired === 1
                        ? "نفذت الكمية"
                        : product.Price + "$"}
                    </h1>
                  </div>
                </div>
              ))}
            </div>
            <div
              id="showBlock"
              className="show_more_gradient w-full flex justify-end items-end z-20 absolute bottom-0 h-full"
            >
              <button
                onClick={handleToggleShow}
                className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300 cursor-pointer m-10 flex items-center gap-1 border border-[#33bbfb] px-6 rounded-md text-xl text-white bg-[#0c0f1484]"
              >
                <h1 className="text-[#33bbfb] mx-3 text-lg">
                  {isOpen ? "عرض أقل" : "عرض الكل"}
                </h1>
                <i
                  className={`text-[#33bbfb] fa-solid ${
                    isOpen ? "fa-chevron-up" : "fa-chevron-down"
                  } transition-all outline-none`}
                />
              </button>
            </div>
          </div>
        </section>
        <section className="max-w-screen-2xl mx-auto">
          <div className="w-full px-8 flex justify-between  md:flex-row-reverse flex-col  md:items-center items-end">
            <div className="ml-3">
              <h1 id="Rules" className="text-white text-2xl text-right ">
                الشروط والأحكام
              </h1>
              <ul className="text-gray-400 mt-5" style={{ direction: "rtl" }}>
                {Object.values(config.Rules).map((term, index) => (
                  <li key={index} className="mb-2">
                    <span className="font-[Conthrax] text-[#33bbfb] mt-2">
                      {" "}
                      -{" "}
                    </span>{" "}
                    {term}
                  </li>
                ))}
              </ul>
            </div>
            <img
              src={Shape}
              alt="shape"
              className="w-full max-w-xs md:max-w-sm my-10"
              loading="lazy"
              draggable="false"
            />
          </div>
        </section>
        <section className="max-w-screen-2xl mx-auto">
          <div className="w-full px-8 flex justify-between  md:flex-row-reverse flex-col  md:items-center items-end">
            <div className="ml-3">
              <h1 id="Terms" className="text-white text-2xl text-right ">
                سياسة الخصوصية
              </h1>
              <ul className="text-gray-400 mt-5" style={{ direction: "rtl" }}>
                {Object.values(config.Terms).map((term, index) => (
                  <li key={index} className="mb-2">
                    <span className="font-[Conthrax] text-[#33bbfb] mt-2">
                      {" "}
                      -{" "}
                    </span>{" "}
                    {term}
                  </li>
                ))}
              </ul>
            </div>
            <img
              src={Hue_Saturation}
              alt="hue"
              className="w-full max-w-xs md:max-w-sm my-10"
              loading="lazy"
              draggable="false"
            />
          </div>
        </section>

      </>
      <Footer />
    </div>
  );
}

export default Home;
