import React from "react";
import { useLocation } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import Logo from "../images/logo_new.png";
import Header from "./Header";
import Footer from "./Footer";

const SuccessPage = () => {
  const location = useLocation();
  const { payerName, invoiceUrl } = location.state;
  const [searchParams] = useSearchParams();
  const invoiceId = searchParams.get('id');

  return (
    <div>
      <Header />
      <section className="error_img">
        <img src={Logo} alt="" />
        <div className="right_err">
          <h1>تم شراء المنتج بنجاح ...</h1>
          <p>
            كود الشراء :
            <span style={{ background: "#262626", padding: "2px 2px" }}>
              {invoiceId}
            </span>
          </p>
          <a dir="rtl" className="back_btn" href="https://discord.gg/b4s" style={{ marginTop: "1.5vh" }}>
            يرجى فتح تذكرة في دسكورد المتجر وارسال
            <br />
            كود الشراء لإستلام المنتج
          </a>
          <a href={invoiceUrl} className="back_btn" style={{ marginTop: "1.5vh" }} target="_blank" rel="noopener noreferrer">
            تحميل الفاتورة
          </a>
          <a href="/" className="back_btn" style={{ marginTop: "1.5vh" }}>
            <i className="fa-solid fa-angle-left" /> العودة للرئيسية
          </a>
          {console.log(invoiceUrl)}
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default SuccessPage;
