import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import "../style/global.css";
import productImg from "../images/product.png";
import Layer2 from "../images/Layer 2.png";
import Header from "../particles/Header";
import Footer from "../particles/Footer";
import Swal from "sweetalert2";
import PayPalButton from "../particles/PayPalButton";

function Check() {
  const location = useLocation();
  const navigate = useNavigate();
  const [cart, setCart] = useState(location.state.cart || []);
  const [coupons, setCoupons] = useState([]);
  const [discount, setDiscount] = useState(0);
  const [discountCode, setDiscountCode] = useState("");
  const [discountApplied, setDiscountApplied] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [CoponInput, setCoponInput] = useState("");
  const apiKey = process.env.REACT_APP_API_KEY;
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    iconColor: "white",
    timer: 4000,
    timerProgressBar: true,
  });

  useEffect(() => {
    const fetchCoupons = async () => {
      try {
        const response = await axios.get("https://api.b4store.net/api/coupons", {
          headers: {
            'Authorization': `Bearer ${apiKey}`,
            'Content-Type': 'application/json'
          }
        });
        const coupons = response.data;
        setCoupons(coupons);
      } catch (error) {
        console.error("Error fetching coupons:", error);
      }
    };

    fetchCoupons();
  }, [apiKey]);

  const handleQuantityChange = (index, quantity) => {
    const updatedCart = [...cart];
    updatedCart[index].Quantity = quantity;
    setCart(updatedCart);
  };

  const calculateTax = () => {
    const taxRate = 4.4;
    return taxRate;
  };

  const calculateSubtotal = () => {
    let subtotal = 0;
    cart.forEach((item) => {
      subtotal += item.Price * item.Quantity;
    });
    return subtotal.toFixed(2);
  };

  const calculateTotal = () => {
    const subtotal = calculateSubtotal();
    const tax = calculateTax();
    const total = subtotal * (1 + tax / 100) * (1 - discount / 100);
    return total.toFixed(2);
  };
  
  
  

  const handleApplyDiscount = () => {
    if (discountApplied) {
      Toast.fire({
        icon: "info",
        title: `تم تطبيق كود الخصم بالفعل`,
        color: "white",
        background: "#33bbfb",
      });
      return;
    }
  
    const coupon = coupons.find((coupon) => coupon.CouponName === discountCode);
  
    if (coupon) {
      let isDiscountApplied = false;
      let discountValue = 0;
  
      cart.forEach((item) => {
        if (coupon.ProductId === item.ProductId && !coupon.SkipFromSectionId) {
          discountValue = Math.max(discountValue, coupon.Discount);
          isDiscountApplied = true;
        } else if (coupon.SectionId === item.SectionId) {
          if (coupon.SkipFromSectionId !== item.ProductId) {
            discountValue = Math.max(discountValue, coupon.Discount);
            isDiscountApplied = true;
          }
        }
      });
  
      if (isDiscountApplied) {
        setDiscount(discountValue);
        setDiscountApplied(true);
        Toast.fire({
          icon: "success",
          title: `تم تطبيق الخصم بنسبة ${discountValue}% بنجاح`,
          color: "white",
          background: "#33bbfb",
        });
      } else {
        Toast.fire({
          icon: "error",
          title: `الخصم لا يشمل المنتج هذا`,
          color: "white",
          background: "#33bbfb",
        });
      }
    } else {
      Toast.fire({
        icon: "error",
        title: `كود الخصم منتهي أو غير صالح للاستخدام`,
        color: "white",
        background: "#33bbfb",
      });
    }
  };
  

  const handlePaymentSuccess = async (details) => {
    if (!userInfo) {
      Toast.fire({
        icon: "error",
        title: "سجل دخولك وحاول مرة اخرى !",
        color: "white",
        background: "#33bbfb",
      });
      return;
    }

    const discordUserId = userInfo;
    const orderData = {
      cart: cart.map(item => ({
        Name: item.Name,
        ProductId: item.ProductId,
        Quantity: item.Quantity,
        Price: item.Price
      })),
      User: discordUserId.id,
      InvoiceId: details.id,
    };

    try {
      await axios.post("https://api.b4store.net/api/orders", orderData, {
        headers: {
          'Authorization': `Bearer ${apiKey}`,
          'Content-Type': 'application/json'
        }
      });
      setCart([]);
      navigate(`/success?id=${details.id}`, {
        state: {
          payerName: details.payer.name.given_name,
        },
      });
    } catch (error) {
      console.error("Error sending order data:", error);
      Toast.fire({
        icon: "error",
        title: "حدث خطأ أثناء معالجة الدفع. يرجى المحاولة مرة أخرى.",
        color: "white",
        background: "#33bbfb",
      });
    }
  };
  
  const handlePaymentSuccessFree = async () => {
    if (!userInfo) {
      Toast.fire({
        icon: "error",
        title: "سجل دخولك وحاول مرة اخرى !",
        color: "white",
        background: "#33bbfb",
      });
      return;
    }

    function generateRandomLetters(number) {
      let result = "";
      let charcters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (let i = 0; i < number; i++) {
        result += charcters[Math.floor(Math.random() * charcters.length)];
      }
      return result;
    }

    const FreeId = generateRandomLetters(6);
    const discordUserId = userInfo;
    const orderData = {
      cart: cart.map(item => ({
        Name: item.Name,
        ProductId: item.ProductId,
        Quantity: item.Quantity,
        Price: item.Price
      })),
      User: discordUserId.id,
      InvoiceId: "Free-" + FreeId,
    };

    try {
      await axios.post("https://api.b4store.net/api/orders", orderData, {
        headers: {
          'Authorization': `Bearer ${apiKey}`,
          'Content-Type': 'application/json'
        }
      });
      setCart([]);
      navigate(`/success?id=${"Free-" + FreeId}`, {
        state: {
          FreeId: FreeId,
        },
      });
    } catch (error) {
      console.error("Error sending order data:", error);
      Toast.fire({
        icon: "error",
        title: "حدث خطأ أثناء معالجة الدفع. يرجى المحاولة مرة أخرى.",
        color: "white",
        background: "#33bbfb",
      });
    }
  };
  
  return (
    <>
      <Header setUserInfo={setUserInfo}/>
      <div
        id="blur"
        className="hidden z-[99] backdrop-blur-sm fixed w-full h-full top-0 left-0"
      />
      <div
        id="cart_side"
        className="transition-all left-[-420px] product_bg top-0 fixed z-[100] w-[295px] sm:w-96 h-screen py-6 px-3 sm:px-6 overflow-scroll no-scrollbar select-none flex flex-col"
      >
        <div className="flex justify-between text-white items-center">
          <button>
            <i className="cursor-pointer fa-solid fa-x text-[#33bbfb]" />
          </button>
          <div className="flex items-center">
            <h1 className="font-bold">سـلة التسـوق</h1>
            <i className="ml-4 text-[#33bbfb] fa-solid fa-basket-shopping" />
          </div>
        </div>
        {/* Ex: CART ITEMS */}
        <div className="my-6 flex justify-between items-center flex-row-reverse text-right">
          <div className="flex w-full  flex-row-reverse text-white">
            <img
              src={productImg}
              className="w-20 ml-3 h-[4rem] rounded-lg"
              alt="product's image"
              loading="lazy"
              draggable="false"
            />
            <div className="flex flex-col justify-between">
              <p>سكربت المدينة</p>
              <div className="flex flex-row-reverse">
                <div className="number flex items-center">
                  <span className="minus cursor-pointer">-</span>
                  <input
                    className="num outline-none"
                    type="text"
                    min={1}
                    max={99}
                    defaultValue={1}
                  />
                  <span className="plus cursor-pointer">+</span>
                </div>
                <h1 className="font-[Conthrax] mx-3">49.99$</h1>
              </div>
            </div>
          </div>
          <button className="bg-[#2c384a] w-10 sm:ml-5 h-8 rounded-full text-center text-[#33bbfb]">
            <i className="text-sm fa-solid fa-trash" />
          </button>
        </div>
        {/* CART ITEMS End */}
        <button className="cursor-pointer w-full rounded-full text-lg text-white bg-[#33bbfb] py-1 mt-auto font-bold hover:bg-transparent transition-all border-2 border-solid border-[#33bbfb]">
          إتمام الشراء
        </button>
      </div>
      {/* CART SIDE MENU End */}
      {/* Header Section End */}
      <section className="w-full p-5 md:p-10 max-w-screen-2xl mx-auto">
        <div className="flex w-full justify-center md:flex-row flex-col-reverse items-center">
          <div className="flex flex-col lg:pr-4 w-full">
            <img
              src={Layer2}
              className="w-[40rem] select-none draggable hidden md:block mx-auto"
            />
          </div>
          <div className="py-10 flex flex-col product_bg my-10 rounded-3xl w-full lg:w-[33rem] p-5">
            {cart.map((item, index) => (
              <div
                key={index}
                className="my-5 flex w-full  flex-row-reverse text-white"
              >
                <img
                  src={item.Image}
                  className="w-28 h-28 ml-3 object-cover rounded-lg"
                  alt={item.Name}
                  loading="lazy"
                  draggable="false"
                />
                <div className="flex flex-col justify-between">
                  <p className="text-xl text-right">{item.Name}</p>
                  <div className="flex flex-row-reverse mb-5">
                    <div className="number flex items-center">
                      <span
                        className="minus cursor-pointer select-none text-xl mx-3"
                        onClick={() =>
                          handleQuantityChange(
                            index,
                            Math.max(1, item.Quantity - 1)
                          )
                        }
                      >
                        -
                      </span>
                      <input
                        className="num outline-none w-8 h-8 font-[Conthrax] mx-3"
                        type="text"
                        min={1}
                        max={99}
                        value={item.Quantity}
                        onChange={(e) =>
                          handleQuantityChange(index, e.target.value)
                        }
                      />
                      <span
                        className="plus cursor-pointer select-none text-xl mx-3"
                        onClick={() =>
                          handleQuantityChange(index, item.Quantity + 1)
                        }
                      >
                        +
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}

            <h1 className="text-right text-white mt-7 text-sm mb-2">
              رمز الخصم
            </h1>
            <div className="flex h-22 flex-row-reverse rounded-lg shadow-sm border-2 border-[#33bbfb]">
              <input
                maxLength={8}
                type="text"
                className="text-right py-3 px-4 block w-full shadow-sm rounded-s-lg text-sm  disabled:opacity-50 disabled:pointer-events-none bg-transparent outline-none text-white"
                value={discountCode}
                onChange={(e) => setDiscountCode(e.target.value)}
              />
              <button
                type="button"
                className="m-1 w-[3rem] flex-shrink-0 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded border border-transparent bg-[#33bbfb] text-white hover:bg-[#1C78A1] disabled:opacity-50 disabled:pointer-events-none"
                onClick={handleApplyDiscount}
              >
                تطبيق
              </button>
            </div>
            <h1 className="font-bold text-right text-white my-4 text-md">
              ملخص الدفع
            </h1>
            <div className="w-full flex justify-between items-center">
              <h1 className="font-[Conthrax] text-white text-sm">
                {calculateSubtotal()} $
              </h1>
              <p className="text-right text-white text-sm">المبلغ قبل الخصم</p>
            </div>
            <div className="w-full flex justify-between items-center">
              <h1 className="font-[Conthrax] text-white text-sm">
                {discount} %
              </h1>
              <p className="text-right text-white text-sm">نسبة الخصم</p>
            </div>
            <div className="w-full h-0.5 my-2 bg-[#333333]" />
            <div className="w-full flex justify-between items-center">
              <h1 className="font-[Conthrax] text-white text-sm">
                {calculateTax()} %
              </h1>
              <p className="text-right text-white text-sm">ضريبة الدفع</p>
            </div>
            <div className="w-full flex justify-between items-center">
              <h1 className={`text-white text-sm ${calculateTotal() === "0.00" ? "font-[Tajawal]" : "font-[Conthrax]"}`}>
                {calculateTotal() === "0.00" ? "مجانا" : `${calculateTotal()} $`}
              </h1>
              <p className="text-right text-white text-sm">المبلغ الإجمالي</p>
            </div>


            {calculateTotal() === "0.00" ? (
              <button onClick={handlePaymentSuccessFree} className="border-2 border-[#33bbfb] rounded-sm text-[#33bbfb] w-full py-2 text-2xl mt-9 mb-2 hover:text-white hover:bg-[#33bbfb] transition-all">
                <i class="fa-solid fa-gift"></i>
              </button>
            ) : (
              <PayPalButton amount={calculateTotal()} onSuccess={handlePaymentSuccess} />
            )}
            
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default Check;
