import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../src/components/Home";
import Products from "./components/Products";
import Check from "./components/Check";
import ShowProducts from "./components/ShowProducts";
import { Helmet } from 'react-helmet';
import Logo from "../src/images/favicon.png";
import SuccessPage from "./particles/SuccessPage"

// import DisableDevtool from 'disable-devtool';

// DisableDevtool();


function Data() {
  return (
    <>
      <Helmet>
        <title>B4 Store</title>
        <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests"></meta>
        <meta property="og:title" content="B4 Store" />
        <meta property="og:description" content="متجر بي فور , يضم الكثير من المبرمجين المحترفين في مجال الألعاب وتقنية المعلومات , يهدف المتجر لتلبية احتياجات اللاعبين في مجال الألعاب وبالتحديد في مجال ألعاب الحياة الواقعية" />
        <meta property="og:url" content="https://b4store.net/" />
        <meta property="og:image" content="https://cdn.discordapp.com/attachments/1056985831912505444/1244905088791609425/Asset_4-8.png?ex=6658c9f6&is=66577876&hm=e9165dfed3b70b0f49468b338c64dd92461a4199936743e8be34980d0a1dcfcc&" />
        <meta name="theme-color" content="#2c384a" />
        <link rel="icon" href={Logo} type="image/x-icon" />
        <script src="https://cdn.moyasar.com/mpf/0.1/moyasar.js"></script>
      </Helmet>

      <BrowserRouter>
      <Routes>
        <Route index path="/" element={<Home />} />
        <Route path="/products" element={<Products />} />
        <Route path="/check" element={<Check />} />
        <Route path="/showProducts" element={<ShowProducts />} />
        <Route path="/Success" element={<SuccessPage />} />
      </Routes>
    </BrowserRouter>
    </>
  );
}

export default Data;
